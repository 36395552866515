import { Button, Row, Col, InputGroup, Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import styles from "./index.module.scss";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DashboardService from "../../services/dashboard";
import CustomTable, { CustomColumn } from "../../components/CustomTable";
import Modal from "../../components/Common/Modal";
import Menu from "../AdminForms/Menu";
import Select from "react-select";
import {
  FilterIcon,
  LockClosedIcon,
  SearchIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FilterIcon as FilterIconFilled } from "@heroicons/react/solid";
import {
  filterRowsDataInCols,
  getUniqueTableColValuesMap,
} from "../../common/utils";

const multiSelectFilterColsNames = [
  "message_status",
  "message_class",
  "job_type",
];

function getFormFields(e) {
  return {
    job_id: e.target.job_id.value,
    uid: e.target.uid.value,
    message_id: e.target.vocado_message_id.value,
    message_class: e.target.message_class.value,
    message_status: e.target.message_status.value,
    start_date: e.target.start_date.value,
    end_date: e.target.end_date.value,
  };
}

const debounce = (fn: Function, ms = 500) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const AdvSearch = (props) => {
  const { passFilteredData, gridData: propData, ...rest } = props;
  // console.log("advsearch ==> ", rest, props);
  const [gridData, setData] = useState([]);
  const [flagsAdvData, setFlagsAdvData] = useState({});
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [showFilterModal, setShowFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [showUidModal, setShowUidModal] = useState(null);
  const [showVocadoMessagesModal, setshowVocadoMessagesModal] = useState(null);
  const [colFilter, setColFilter] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [colValsMapData, setColValsMapData] = useState(new Map());
  const formRef = useRef(null);
  const ref = useRef();

  const renderSelColHeader = useCallback(
    (colName, col) => {
      const uniqId = col?.name;
      const uniqVals = colValsMapData.get(uniqId);
      return (
        <Row className="position-relative">
          <Col>{colName}</Col>
          <span className="position-absolute w-auto" style={{ right: 0 }}>
            <Menu
              id={colName.replace(/ /g, "").toLowerCase()}
              trigger={
                !!colFilter[uniqId] ? (
                  <FilterIconFilled width="16" />
                ) : (
                  <FilterIcon width="16" />
                )
              }
              // trigger={<FilterIcon width="16"  fill={props.filters[props.id] ? '#707070' : 'lightgray'} stroke={props.filters[props.id] ? '#707070' : ''} />}
              className={styles.menu}
            >
              <Select
                className={styles.filerInput}
                options={Array.from(uniqVals || new Set()).map((colVal) => ({
                  value: colVal,
                  label: colVal,
                }))}
                isMulti
                isClearable
                onChange={(val) => {
                  if (val.length) {
                    setColFilter((prev) => ({ ...prev, [uniqId]: val }));
                  } else {
                    setColFilter((prev) => {
                      delete prev[uniqId];
                      return { ...prev };
                    });
                  }
                }}
                value={colFilter[uniqId]}
              />
            </Menu>
          </span>
        </Row>
      );
    },
    [colFilter, colValsMapData]
  );

  const renderColHeader = useCallback(
    (colName, col) => {
      const uniqId = col?.name;
      return (
        <Row className="position-relative">
          <Col>{colName}</Col>
          <span className="position-absolute w-auto" style={{ right: 0 }}>
            <Menu
              id={colName.replace(/ /g, "").toLowerCase()}
              trigger={
                !!colFilter[uniqId] ? (
                  <FilterIconFilled width="16" />
                ) : (
                  <FilterIcon width="16" />
                )
              }
              className={styles.menu}
            >
              <InputGroup className={styles.filerInput} size="sm">
                <InputGroup.Text id={uniqId}>
                  <SearchIcon width="16" />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Small"
                  aria-describedby={uniqId}
                  onChange={({ target: { value } }) =>
                    setColFilter((prev) => ({
                      ...prev,
                      [uniqId]: value.trim(),
                    }))
                  }
                  value={colFilter[uniqId]}
                />
                <Button
                  onClick={() =>
                    setColFilter({
                      ...colFilter,
                      [uniqId]: "",
                    })
                  }
                >
                  <XIcon width="16" />
                </Button>
              </InputGroup>
            </Menu>
          </span>
        </Row>
      );
    },
    [colFilter]
  );

  useEffect(() => {
    const filterFn = async () => {
      const data = await filterRowsDataInCols(colFilter, gridData);
      setFilteredData(data);
    };
    filterFn();
  }, [gridData, colFilter]);

  async function fetchAdvFlags(id, title, isXML = false) {
    const data = await DashboardService.getVocadoMessageXml(id)
    // const { data } = res;
    console.log(data);
    if (data) {
      setshowVocadoMessagesModal({
        title,
        data,
        isXML,
      });
    } else {
      setshowVocadoMessagesModal({
        title,
      });
    }
  }

  const columns: Array<CustomColumn> = [
    {
      name: "job_id",
      label: "Job ID",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: styles.headerCellClass,
      showInExcel: true,
      renderHeader: renderColHeader,
    },
    {
      name: "uid",
      label: "UID",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: styles.headerCellClass,
      showInExcel: true,
      renderHeader: renderColHeader,
      renderRow: (value, row, index) => {
        return row.message ? (
          <a
            href="#"
            onClick={() => {
              if (row.message) {
                console.log(
                  // `${value} - ${(row.message as any)?.message_class}`
                  `${value} - ${row.message_class}`
                );
                setShowUidModal({
                  title: `${value} - ${row.message_class}`,
                  data: row.message,
                });
              }
            }}
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              width: "150px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {value}
          </a>
        ) : (
          value
        );
      },
    },
    {
      name: "message_status",
      label: "Message Status",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: styles.headerCellClass,
      showInExcel: true,
      renderHeader: renderSelColHeader,
      renderRow: (value, row, index) => {
        return row.message_status == "Failed" ||
          row.message_status == "Validation_Error" ||
          row.message_status == "Hold" ? (
          <a
            href="#"
            onClick={() => {
              if (
                row.message_status == "Failed" ||
                row.message_status == "Validation_Error" ||
                row.message_status == "Hold"
              ) {
                console.log(
                  // `${value} - ${(row.message as any)?.message_class}`
                  `${value} - ${row.message_class}`
                );
                setShowModal({
                  title: `Vocado Message Errors`,
                  data: row.message_errors,
                });
              }
            }}
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              width: "150px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {value}
          </a>
        ) : (
          value
        );
      },
    },
    {
      name: "message_class",
      label: "Message Class",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: styles.headerCellClass,
      showInExcel: true,
      renderHeader: renderSelColHeader,
    },
    {
      name: "job_type",
      label: "Job Type",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: styles.headerCellClass,
      showInExcel: true,
      renderHeader: renderSelColHeader,
    },
    {
      name: "message_id",
      label: "Vocado Message ID",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: styles.headerCellClass,
      showInExcel: true,
      renderRow: (value, row, index) => {
        return row.message_id ? (
          <a
            href="#"
            onClick={() => {
              if (row.message_id) {
                fetchAdvFlags(
                  row.message_id,
                  `${row.uid} - ${row.message_class} : ${row.job_id}`
                );
              }
            }}
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              width: "150px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {value}
          </a>
        ) : (
          value
        );
      },
    },
    {
      name: "created_at",
      label: "Created At",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: styles.headerCellClass,
      showInExcel: true,
      sortable: true,
    },
    {
      name: "updated_at",
      label: "Updated At",
      cellClass: `${styles.uid1_col} ${styles.cellClass}`,
      headerClass: styles.headerCellClass,
      showInExcel: true,
      sortable: true,
    },
  ];

  useEffect(() => {
    const getColVals = async () => {
      const data = await getUniqueTableColValuesMap(
        gridData,
        multiSelectFilterColsNames
      );
      console.log("data ==> ", data);
      setColValsMapData(data);
    };
    getColVals();
  }, [gridData, multiSelectFilterColsNames]);

  useEffect(() => {
    if (propData) {
      // setData(propData);
      setData(
        props.gridData
          .map((d) => ({ ...d, created_at: new Date(d.created_at).valueOf() }))
          .sort((a, b) => b.created_at - a.created_at)
          .map((d) => ({
            ...d,
            created_at: new Date(d.created_at).toISOString(),
          }))
      );
    }
  }, [propData]);
  // }, []);

  const clearAllFilters = useCallback(() => {
    formRef?.current?.reset();
    setData([]);
    setColFilter({});
    setFilteredData([]);
    setColValsMapData(new Map());
  }, []);

  const handleSearch = async (e) => {
    (ref.current as any)?.setPage(1);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const fields = getFormFields(e);
    const nonFilledLength = Object.values(fields).filter(
      (field) => field === "" || field === "-1"
    ).length;
    if (nonFilledLength === Object.values(fields).length) {
      setFormError(true);
      return;
    }
    setFormError(false);

    const {
      job_id,
      uid,
      message_id,
      message_class,
      message_status,
      end_date,
      start_date,
    } = fields;
    const payload = {
      job_id,
      uid,
      message_id,
      message_class: message_class === "-1" ? "" : message_class,
      message_status: message_status === "-1" ? "" : message_status,
      endDate: end_date ? end_date : "",
      startDate: start_date ? start_date : "",
    };
    setIsLoading(true);
    const data = await DashboardService.advanceSearch(payload);
    setIsLoading(false);
    setData(data?.resData.data);
  };

  return (
    <div className="">
      <div className="small d-flex justify-content-start gap-1">
        <b>
          <span className="text-danger">*</span>Please apply filters to get the
          table data.
        </b>
      </div>
      {formError && (
        <p className={styles.pos_error}>Please select any search criteria</p>
      )}
      <Form onSubmit={onFormSubmit} ref={formRef}>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group className="mb-3" controlId="uid">
              <Form.Label>
                UID<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter UID *"
                size="sm"
                required
              />
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Message Class</Form.Label>
              <Form.Select
                name="message_class"
                size="sm"
                aria-label="Default select example"
              >
                <option value="">Select Message class</option>
                <option value="FasStudentInitiationEvent">
                  FasStudentInitiationEvent
                </option>
                <option value="FasStudentUpdateEvent">
                  FasStudentUpdateEvent
                </option>
                <option value="FasSafiSyncEvent">FasSafiSyncEvent</option>
                <option value="FasDocumentReceiptEvent">
                  FasDocumentReceiptEvent
                </option>
                <option value="FasDocumentReceiptEventAda">
                  FasDocumentReceiptEventAda
                </option>
                <option value="FasDocumentReceiptEventAward">
                  FasDocumentReceiptEventAward
                </option>
                <option value="FasDocumentReceiptEventDra">
                  FasDocumentReceiptEventDra
                </option>
                <option value="FasAtWillRepackageEvent">
                  FasAtWillRepackageEvent
                </option>
                <option value="FasPlaceStudentRecordHoldEvent">
                  FasPlaceStudentRecordHoldEvent
                </option>
                <option value="FasReleaseStudentRecordHoldEvent">
                  FasReleaseStudentRecordHoldEvent
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Message Status</Form.Label>
              <Form.Select
                name="message_status"
                aria-label="Default select example"
                size="sm"
              >
                <option value="">Select Message Status</option>
                <option value="Failed">Failed</option>
                <option value="Success">Success</option>
                <option value="Error">Error</option>
                <option value="Pending">Pending</option>
                <option value="Validation_Error">Hold</option>
                <option value="Validation_Error">Validation_Error</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group className="mb-3" controlId="vocado_message_id">
              <Form.Label>Vocado Message ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Vocado Message ID"
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col sm={12} md={5}>
            <Form.Group className="mb-3" controlId="job_id">
              <Form.Label>Job ID</Form.Label>
              <Form.Control type="text" placeholder="Enter Job Id" size="sm" />
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group className="" controlId="start_date">
              <Form.Label>Start Date</Form.Label>
              <Form.Control type="date" placeholder="Date" size="sm" />
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group className="" controlId="end_date">
              <Form.Label>End Date</Form.Label>
              <Form.Control type="date" placeholder="Date" size="sm" />
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-2 gap-3">
          <Button variant="primary" size="sm" type="submit">
            Search
          </Button>
          <Button variant="secondary" size="sm" onClick={clearAllFilters}>
            Clear
          </Button>
        </div>
      </Form>
      <div className="d-flex justify-content-center">
        {!isLoading ? (
          <CustomTable
            parentClass={styles.fullTableWidth as any}
            style={{ tableLayout: "fixed" }}
            rows={filteredData}
            columns={columns}
            // headerCellClass={styles.headerCellClass}
            // bodyCellClass={styles.cellClass}
            bordered
            ref={ref}
            rowsPerPage={process.env.REACT_APP_ROWS_PAGE}
            rowClass="test"
            responsive
            striped
            title=""
            hasTableActions
            tableActionProps={{
              hasAddRow: false,
              hasSearch: false,
              hasDownload: false,
            }}
            pagination="default"
          />
        ) : (
          ""
        )}
      </div>
      {!!showUidModal && (
        <Modal
          handleClose={() => setShowUidModal(false)}
          className={styles.adv_modal}
          size="lg"
          config={{
            title: showUidModal.title,
            footer: false,
            body: (
              <div className={styles.Modal_Body}>
                <pre>{JSON.stringify(showUidModal.data, undefined, 2)}</pre>
              </div>
            ),
          }}
        />
      )}
      {!!showModal && (
        <Modal
          handleClose={() => setShowModal(false)}
          className={styles.adv_modal}
          size="lg"
          config={{
            title: showModal.title,
            footer: false,
            body: (
              <div className={styles.Modal_Body}>
                {showModal.data ? (
                  <pre>{showModal.data}</pre>
                ) : (
                  "Message Errors are not found"
                )}
              </div>
            ),
          }}
        />
      )}
      {!!showVocadoMessagesModal && (
        <Modal
          handleClose={() => setshowVocadoMessagesModal(false)}
          className={styles.vocado_modal}
          config={{
            title: showVocadoMessagesModal.title,
            footer: false,
            body: (
              <div className="word-break-all">
                {showVocadoMessagesModal.data ? (
                  <pre>{showVocadoMessagesModal.data}</pre>
                ) : (
                  "Message not found"
                )}
              </div>
            ),
          }}
        />
      )}
    </div>
  );
};
export default AdvSearch;
